import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-fields"
export default class extends Controller {
  static targets = ["phoneNumber", "fullName", "alert"]
  static values = { clients: Array }

  fillPhoneNumber(e) {
    const fullName = e.target.value
    const client = this.clientsValue.find(({full_name}) => full_name === fullName)
    if (client) {
      this.phoneNumberTarget.value = client["phone_number"]
    } else {
      this.phoneNumberTarget.value = "+"
    }
  }

  searchByPhoneNumber(e) {
    const phoneNumber = e.target.value
    if (e.target.value.length >= 8) {
      const client = this.clientsValue.find(({phone_number}) =>
        phone_number === phoneNumber
      )
      if (client) {
        this.fullNameTarget.tomselect.setValue(client["full_name"])
        this.showAlert(`this number already exists by the name of ${client["full_name"]}`)
      }
    }
  }
  showAlert(message)  {
    this.alertTarget.innerHTML = "";
    const alertDiv = document.createElement("div")
    alertDiv.className = "alert alert-success"
    alertDiv.setAttribute("role","alert")
    alertDiv.innerHTML = `
     <div> ${message} </div>
      <button type="button" class="close" data-dismiss="alert" onclick="this.parentElement.style.display='none'" aria-label="Close">
        <span aria-hidden="true">ok</span>
      </button>
    `
    this.alertTarget.appendChild(alertDiv)
    }
}
